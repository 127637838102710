import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import imgView from '../images/view.svg';
import { redirect } from 'react-router-dom';

function FormRegisterPopUp(props) {
  const [show, setShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // State for success message


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //const navigate = useNavigate(); // React Router's useNavigate hook

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    password: '',
    confirmPassword: '',
    profilePic: null,
    roleId: 1, // default role 'user'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file, 'file')
    setFormData(prevState => ({
      ...prevState,
      profilePic: file
    }));
  };

  
  
  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Perform form validation
    if (formData.password !== formData.confirmPassword) {
      alert('Passwords do not match');
      return;
    }
  
    const formDataToSend = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      mobileNumber: formData.mobileNumber,
      password: formData.password,
      roleId: formData.roleId,
    };
  
    // Submit form data using axios
    axios.post('https://www.astsoftwaresolutions.in/api/register', formDataToSend, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        // If registration is successful
        if (response.status === 200) {
          setSuccessMessage('Registration successful! You can login now...');
          handleClose(); // Close registration modal
          // Show login modal (if available)
          if (props.FormPopUp) {
            props.FormPopUp(); // Call parent function to open the login popup
          } else {
            // Redirect to login if no modal is provided
            //window.location.href = '/profile'; // login page need to be
          }
        } else {
          alert('Sign up failed. Please try again.');
        }
      })
      .catch((error) => {
        // Handle API errors
        if (error.response) {
          const { status, data } = error.response;
  
          // Conflict (Email already in use)
          if (status === 409) {
            alert(`Sign up failed: ${data.message}`);
          } 
          // Other server errors
          else if (status === 500) {
            alert('Internal server error. Please try again later.');
          } else {
            alert(`Sign up failed: ${data.message || 'An unknown error occurred'}`);
          }
        } else {
          // Network or unexpected issues
          alert('Sign up failed due to network issues. Please check your connection and try again.');
        }
      });
  }

  return (
    <>
      <Button  className={props.className} onClick={handleShow}>
        {props.name}
      </Button>
      

      <Modal className='loginModal' show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter"
        centered>
       
        <Modal.Body className='p-4 p-sm-4 p-md-5'>
          <h4>To Become a Star - Join ClikCineCraft !</h4>
          <Button variant="close position-absolute top-20 right-20" onClick={handleClose}>
            
          </Button>
        <div className="tab-pane fade show active" id="login-tab-content" role="tabpanel" aria-labelledby="login-tab">
        {successMessage && (
        <div className="alert alert-success text-center">
          {successMessage}
        </div>
      )}
              <form name="login_2" id="login_2" method="post" className="needs-validation row g-3"  onSubmit={handleSubmit} novalidate="">
              {/* <div className="col-md-12">
                  <label className="form-label m-0 mb-1 font-18 font-500 w-100" for="cloud-computing">Upload Image</label>
                 
                  <div className="upload_section">
                    <input type="file" id="cloud-computing" className="" onChange={handleFileChange}/>
                      <div className="row g-2 align-items-center justify-content-center">
                        <div className="col-sm d-flex align-items-center justify-content-center">
                          <div className="cloud-wrap px-3 px-sm-5 mx-auto">
                            <div className="cloud-img"><img src={imgCloudComputing} alt=""/></div>
                            <div className="cloud-text">Drag and drop here or <a href="javascript:;" className="text-sky-blue">Browse</a></div>
                          </div>                          
                        </div>
                       
                      </div>
                  </div>
                </div> */}
                <div className='col-md-6'>
                  <div className="form-group form-control-email">
                      <input type="email" className="form-control" id="ShopLoginFormModel_email" placeholder="First Name" name="firstName" value={formData.firstName} onChange={handleChange} required=""/>
                      <div className="valid-feedback">
                          Looks good!
                      </div>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className="form-group form-control-email">
                      <input type="email" className="form-control" id="ShopLoginFormModel_email" placeholder="Last Name" name="lastName" value={formData.lastName} onChange={handleChange} required=""/>
                      <div className="valid-feedback">
                          Looks good!
                      </div>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className="form-group form-control-email">
                      <input type="email" className="form-control" id="ShopLoginFormModel_email" placeholder="Mobile Number" name="mobileNumber" value={formData.mobileNumber} onChange={handleChange}  required=""/>
                      <div className="valid-feedback">
                          Looks good!
                      </div>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className="form-group form-control-email">
                      <input type="email" className="form-control" id="ShopLoginFormModel_email" placeholder="Email" name="email" value={formData.email} onChange={handleChange} required=""/>
                      <div className="valid-feedback">
                          Looks good!
                      </div>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className="form-group form-control-password">
                    <input type="password" className="form-control" id="ShopLoginFormModel_password" name="password" value={formData.password} onChange={handleChange} placeholder="Password"/>
                    <a className="viewpassword" href="#!" onclick="Toggle()"><img src={imgView}/></a>
                  </div>
                
                </div>

                <div className='col-md-6'>
                  <div className="form-group form-control-password">
                    <input type="password" className="form-control" id="ShopLoginFormModel_password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} placeholder="Confirm Password"/>
                    <a className="viewpassword" href="#!" onclick="Toggle()"><img src={imgView}/></a>
                  </div>
                
                </div>
                <div className='col-12 d-flex justify-content-center'>
                  <button type="submit" className="btn-login"><span>Sign Up</span></button>
                </div>
                
                
                
            </form>
        </div>
        </Modal.Body>
        {/* <Modal.Footer>
          
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default FormRegisterPopUp;
