import React from 'react';
import logoImg from '../images/main-logo.png';

import FormPopUp from './FormPopUp';
import FormRegisterPopUp from './FormRegisterPopUp';

import VideoModalPopup from './VideoModalPopup';

function Navigation() {
  return (
    <div className='col-xl col-sm-auto col d-flex justify-content-end'>
                          <button className="navbar-toggler nav-toggle" type="button" data-bs-toggle="offcanvas" href="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                              <span className="navbar-toggler-icon"><b></b></span>
                          </button>
                      <div className="offcanvas offcanvas-start" id="navbarNavDropdown">
                        <div className="mobile-new-header d-block d-xl-none">
                            <div className="row g-3">
                                <div className="col">
                                    <a className="navbar-brand" href="/"><img src={logoImg} alt='univariety' /></a>
                                </div>
                                <div className="col-auto d-flex align-items-center">
                                    
                                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                </div>
                                <div className='col-12 d-block d-sm-none'>
                                    <FormPopUp className='btn btn-login me-2 border-0' name="Login"/>
                                    <FormRegisterPopUp className='btn btn-register me-2 border-0' name="Register"/>
                                </div>
                            </div>
                            

                        </div>
                      <ul className="navbar-nav justify-content-md-center" data-aos="fade-left" data-aos-delay="200">
                        
                          <li className="nav-item">
                              <a className="nav-link active" aria-current="page" href='/' >
                                  Home
                              </a>
                          </li>
                          <li className="nav-item">
                              <a className="nav-link" href='/about-us'>About Us</a>
                          </li>
                          {/* <li className="nav-item dropdown">
                              <a className="nav-link" href='/category'>Category</a>
                              

                          </li> */}
                          <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="collapse" href="#collapseSolutions" aria-expanded="false">Category</a>
                            <div className="collapse dropdown-list" id="collapseSolutions">
                                <div className='row g-0 g-xl-3'>
                                    <div className="col-xl-3">
                                        <ul>
                                            <li><a className="dropdown-item" target="_blank" href="#">Actors</a></li>
                                            <li><a className="dropdown-item" target="_blank" href="#">Producers</a></li>
                                            <li><a className="dropdown-item" target="_blank" href="#">Director</a></li>
                                            <li><a className="dropdown-item" target="_blank" href="#">Music Director</a></li>
                                            <li><a className="dropdown-item" target="_blank" href="#">Dubbing Artist</a></li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-3">
                                        <ul>
                                            <li><a className="dropdown-item" target="_blank" href="#">Technicians</a></li>
                                            <li><a className="dropdown-item" target="_blank" href="#">Art Director</a></li>
                                            <li><a className="dropdown-item" target="_blank" href="#">Camera man</a></li>
                                            <li><a className="dropdown-item" target="_blank" href="#">Editor</a></li>
                                            <li><a className="dropdown-item" target="_blank" href="#">VFX</a></li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-3">
                                        <ul>
                                            <li><a className="dropdown-item" target="_blank" href="#">Brand Promotion</a></li>
                                            <li><a className="dropdown-item" target="_blank" href="#">Film Promotion</a></li>
                                            <li><a className="dropdown-item" target="_blank" href="#">Locations</a></li>
                                            <li><a className="dropdown-item" target="_blank" href="#">Writers</a></li>
                                            <li><a className="dropdown-item" target="_blank" href="#">Theatres</a></li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-3">
                                        <ul>
                                            <li><a className="dropdown-item" target="_blank" href="#">Equipment</a></li>
                                            <li><a className="dropdown-item" target="_blank" href="#">Awards</a></li>
                                            <li><a className="dropdown-item" target="_blank" href="#">Celebrities</a></li>
                          
                                        </ul>
                                    </div>
                                </div>
                                
                                </div>
                            </li>
                          <li className="nav-item">
                              <a className="nav-link" href='/articles'>Articles</a>
                          </li>
                          <li className="nav-item">
                              <a className="nav-link" href='/news'>News</a>
                          </li>
                          <li className="nav-item">
                              <a className="nav-link" href='/reviews'>Reviews</a>
                          </li>
                         
                          
                          
                      </ul>
                          </div>
                          </div>
  )
}

export default Navigation;